@import "./partials/mixins";
@import "./partials/pallete";
@import "./partials/typography";

// spacing

body {
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.nav {
  @include transition;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;

  a {
    @include no-link;
  }

  ul {
    @include no-list;
    display: flex;

    li {
      padding: 0 0.5rem;
    }
  }

  &.scroll {
    @include transition;
    background-color: rgba($primary, 1);
  }
}

#connectWallet {
  position: fixed;
  z-index: 999;
  width: 100%;
  padding-top: $pt-min;
  padding-bottom: $pb-min;
  pointer-events: none;
  button,
  a {
    pointer-events: auto;
  }

  button {
    display: inline-flex;
    align-items: center;
    @include transition;
    margin-right: 1rem;

    span {
      display: block;
      margin-right: 0.5rem;
      border: solid 2px $red;
      width: 8px;
      height: 8px;
      border-radius: 100px;

      &.connected {
        border-color: $green;
        background-color: $green;
      }
    }
  }
}

.head {
  min-height: 100vh;
  position: relative;
  z-index: 0;
  background-image: url(asstes/bg-assets/background.PNG);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;

  //scroll animation

  .icon-scroll,
  .icon-scroll:before {
    position: absolute;
    left: 50%;
  }
  .icon-scroll {
    width: 30px;
    height: 50px;
    margin-left: -20px;
    bottom: 5%;
    margin-top: -35px;
    box-shadow: inset 0 0 0 4px #f9e5cc;
    border-radius: 25px;
  }

  .icon-scroll:before {
    content: "";
    width: 6px;
    height: 6px;
    background: #f9e5cc;
    margin-left: -3px;
    top: 8px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      transform: translateY(46px);
    }
  }

  // .scroll-anim {
  //   position: absolute;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translate(-50%);
  //   filter: saturate(0.5);
  //   span {
  //     position: absolute;
  //     top: 0;
  //     background: $primary;
  //     height: 100%;
  //     width: 25px;
  //     filter: blur(20px);
  //     @include transformX(50%);
  //   }
  //   img {
  //     height: 100px;
  //     position: relative;
  //   }
  // }

  @include media-breakpoint-down(sm) {
    background-position: 60%;
  }
  @include media-breakpoint-down(lg) {
    background-position: 70%;
  }

  #title {
    position: absolute;
    top: 0%;
    left: 12%;
    z-index: 6;
    width: 350px;
    margin-top: 2rem;

    img {
      width: 100%;
    }

    h1 {
      color: $primary;
      font-size: 2.5rem;
      position: absolute;
      @include transform(50%, 50%);
      white-space: nowrap;
    }

    // @include media-breakpoint-down(lg) {
    //   width: 350;
    //   h1 {
    //     font-size: 3rem;
    //   }
    // }

    @include media-breakpoint-down(lg) {
      @include transformX(50%);
      margin-top: 6rem;
    }
    @include media-breakpoint-down(sm) {
      width: 250px;

      h1 {
        font-size: 1.8rem;
      }
    }
  }

  .head-assets {
    position: absolute;
    img {
      width: 70%;
      height: auto;

      @include media-breakpoint-down(xl) {
        width: 60%;
      }
      @include media-breakpoint-down(lg) {
        width: 50%;
      }
    }

    &#monkey {
      bottom: -7%;
      left: 51%;
      @include rotateImgWrapper(218deg);

      @include media-breakpoint-down(lg) {
        bottom: 5%;
        left: -21%;
      }
    }
    &#alien {
      bottom: 3%;
      right: 12%;
      @include rotateImgWrapper(170deg);
    }
    &#ship {
      top: 20%;
      left: 26%;
      @include rotateImgWrapper(240deg);

      @include media-breakpoint-down(lg) {
        left: -10%;
        top: 45%;
        img {
          width: 40%;
        }
      }
      @include media-breakpoint-down(sm) {
        left: -30%;
        top: 45%;
        img {
          width: 40%;
        }
      }
    }
    &#robot {
      bottom: 22%;
      right: 9%;
      @include rotateImgWrapper(24deg);
    }
    &#dismembered {
      bottom: 9%;
      right: 6%;
      @include rotateImgWrapper(85deg);
    }
    &#apple {
      bottom: 40%;
      right: 6%;
      @include rotateImgWrapper(50deg);
    }
    &#skull {
      bottom: -4%;
      left: 2%;
      @include rotateImgWrapper(300deg);
    }
    &#suit {
      top: 18%;
      left: 20%;
      @include rotateImgWrapper(296deg);
    }
  }
}

.about {
  padding-top: $pt-max;
  padding-bottom: $pb-mid;
  background-color: $primary;

  @include media-breakpoint-down(lg) {
    padding-top: $pt-mid;
    padding-bottom: $pb-min;
  }
  p {
    font-size: 120%;
    @include media-breakpoint-down(md) {
      font-size: 110%;
      margin-bottom: 2rem;
    }
  }

  span {
    color: $text-accent;
  }
  .image {
    position: relative;
    max-width: 400px;
    img {
      border-radius: 15px;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1;

      &.gif {
        position: absolute;
        // width: 81%;
        width: 60%;
        @include transform(50%, 50%);
        left: 49%;
      }

      &.right,
      &.left {
        position: absolute;
        height: 40%;
        width: auto;
        z-index: 0;
      }

      &.right {
        top: 10%;
        right: -9%;
        @include media-breakpoint-down(md) {
          right: -12px;
        }
      }
      &.left {
        top: 5%;
        left: -15%;
        @include media-breakpoint-down(md) {
          left: -12px;
        }
      }
    }
  }

  .dot {
    position: absolute;

    img {
      width: auto;
    }

    &.sm {
      top: 25%;
      right: 0;
      height: 10px;
      @include media-breakpoint-down(lg) {
        top: 15%;
        right: 5%;
      }
    }

    &.md {
      top: 5%;
      right: 10%;
      height: 15px;
      @include media-breakpoint-down(lg) {
        top: 0;
        right: 10%;
      }
      &.two {
        top: 55%;
        left: -12%;
        @include media-breakpoint-down(lg) {
          top: 15%;
          left: 5%;
        }
      }
    }
    &.lg {
      top: 8%;
      right: 1%;
      height: 20px;

      @include media-breakpoint-down(lg) {
        top: 5%;
        right: 3%;
      }

      &.two {
        top: 40%;
        left: -8%;
        @include media-breakpoint-down(lg) {
          top: 5%;
          left: 9%;
        }
      }
    }
  }
}

.characteristics {
  background-color: $primary;
  padding-bottom: $pb-max;
  @include media-breakpoint-down(lg) {
    padding-bottom: $pb-mid;
  }

  .sub-title {
    position: relative;
    margin-bottom: 2rem;
    text-align: center;

    h2 {
      position: absolute;
      color: $primary;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .characters {
    margin-bottom: $mb-min;
    p {
      font-size: 120%;

      @include media-breakpoint-down(md) {
        font-size: 110%;
        margin-bottom: 2rem;
      }
    }

    .planet {
      text-align: center;
      max-width: 400px;
    }
    .sub-title {
      img {
        height: 160px;
        width: auto;
      }
      h2 {
        top: 46%;
        @include transformX(50%);
      }

      @include media-breakpoint-down(sm) {
        img {
          height: 90px;
        }
      }
    }
  }

  .assets {
    position: relative;
    p {
      font-size: 120%;
      margin-bottom: 2rem;
      @include media-breakpoint-down(md) {
        font-size: 110%;
      }
    }
    .sub-title {
      img {
        height: 210px;
        width: auto;
      }
      h2 {
        top: 46%;
        @include transformX(50%);
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0rem;
        img {
          height: 110px;
        }
      }
    }

    ul {
      @include no-list;
      display: flex;
      white-space: nowrap;
      flex-wrap: wrap;
      padding: 0;
      li {
        cursor: pointer;
        background-color: rgba($white, 0.05);
        padding: 0.5rem;
        margin: 1%;
        border-radius: 5px;
        width: 48%;
        display: flex;
        justify-content: center;
        flex-grow: 1;

        h3 {
          margin: 0;
          font-size: 1.3rem;
        }

        &:hover {
          padding-left: 0.5rem;
          background-color: rgba($white, 0.1);

          @include transition;
          h3 {
            color: $text-accent;
          }
        }
      }
    }
    .example-assets {
      position: relative;
      height: 100%;
      img {
        border-radius: 15px;
        position: absolute;
        height: auto;
        width: 56%;
        @include transition;
        @include transform(50%, 50%);
        top: 54%;
        left: 40%;

        &.tv {
          position: relative;
          left: 0;
          width: 100%;
          height: auto;
          @include transformY(50%);
          
          @include media-breakpoint-down(lg) {
            @include transformY(0);
          }
        }
        &.bg2 {
          width: 65%;
          height: auto;
        }
        &.static {
          transform: translate(-50%, -50%) scale(1.1);
          opacity: 0.15;
        }
        &.blured {
          filter: blur(1px) sepia(100%) opacity(0.6);
          @include transition;

          &.background,
          .bg2 {
            filter: opacity(1);
          }
        }
      }

      @include media-breakpoint-down(lg) {
        margin: 0 4rem;
        height: auto;

        img {
          top: 55%;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }
  }
}

.team {
  padding-top: $pt-max;
  padding-bottom: $pb-mid;
  background-color: $white;
  @include media-breakpoint-down(lg) {
    padding-top: $pt-mid;
    padding-bottom: $pb-min;
  }

  .arrow {
    position: absolute;
    top: -50%;
    right: 0;
    width: 50%;

    @include media-breakpoint-down(xxl) {
      top: -30%;
    width: 60%;

    }
    @include media-breakpoint-down(xl) {
      top: -10%;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .team-member {
    width: 31%;
    margin: 1%;
    display: flex;
    flex-flow: column;
    border-radius: 15px;
    padding: 1rem;
    box-shadow: rgba($text-accent, 0.2) 0 0 100px 2px;
    background-color: white;

    &:hover,
    &:focus {
      @include transformY(0.5rem);
      .social {
        color: $text-accent;
        @include transition;
      }
    }

    .image {
      margin-bottom: 1rem;
      img {
        border-radius: 15px;
        width: 100%;
        height: auto;
      }
    }

    h4,
    & > h5 {
      text-align: center;
      margin-bottom: 0;
    }

    .details {
      padding: 2rem 1rem 0 1rem;

      .social {
        padding-top: 1rem;
        @include transition;
      }
    }

    @include media-breakpoint-down(xl) {
      width: 48%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0.5rem 0;

      h5 {
        font-size: 0.8rem;
      }
    }

    // @include media-breakpoint-down(lg) {
    //   padding: 0;
    // }
  }

  svg {
    margin-right: 1rem;
  }
}

.gallery {
  padding-top: $pt-mid;
  padding-bottom: $pb-mid;
  background-color: $white;
  @include media-breakpoint-down(lg) {
    padding-top: $pt-min;
    padding-bottom: $pb-min;
  }

  .slider-wrapper {
    position: relative;

    .slick-slide {
      div {
        padding: 1rem;
        @include transition;
        img {
          border-radius: 1rem;
        }
      }
      &.slick-current {
        div {
          padding: 0 0.5rem;
          @include transition;
        }
      }
    }

    .slider-buttons {
      display: flex;
      position: absolute;
      @include transformY(50%);
      justify-content: space-between;
      width: 100%;
      cursor: pointer;

      span {
        border-radius: 50px;
        background-color: rgba($primary, 0.05);
        @include transition;
        padding: 0.25rem;
        &:hover {
          @include transition;
          background-color: rgba($primary, 0.1);
        }
      }
    }
  }
}

.foot {
  padding: 1rem 0;
  background-color: $primary;

  .socials {
    a {
      margin-right: 0.5rem;
    }
  }
  .links > p {
    margin-bottom: 0;
    & > a {
      margin-right: 1rem;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-flow: column;
      a {
        margin: 0;
      }
    }
  }
}

.roadmap {
  padding-top: $pt-mid;
  padding-bottom: $pb-mid;
  background-color: $white;
  @include media-breakpoint-down(lg) {
    padding-bottom: $pb-min;
    padding-top: $pt-min;
  }

  h2 {
    margin-bottom: 0;
    margin-top: 5%;
    @include media-breakpoint-down(lg) {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
  }
  .arrows {
    margin-bottom: 2rem;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .list {
    display: flex;
    flex-flow: column;
    align-items: center;

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      border-radius: 15px;
      padding: 1rem;
      margin: 0.5rem;
      h5,
      p,
      i {
        color: $text-dark;
        margin-bottom: 0;
      }
      i {
        padding: 0.3rem 0.5rem;
        border-radius: 100px;
        background-color: rgba($text-accent, 0.1);
        svg {
          visibility: hidden;
        }
      }
      h5 {
        min-width: 45px;
      }
      p {
        flex-grow: 1;
        padding-left: 4rem;
        padding-right: 4rem;

        @include media-breakpoint-down(lg) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      &.active {
        background-color: $primary;
        h5,
        p,
        i {
          color: $text-light;
        }

        i {
          padding: 0.3rem 0.5rem;
          border-radius: 100px;
          background-color: rgba($text-accent, 0.1);
          color: $text-accent;
          svg {
            visibility: visible;
          }
        }
      }
    }
  }
}

//simple
img {
  max-width: 100%;
}
a {
  @include no-link;
}

//spacing
.p-max {
  padding-top: $pt-max;
  padding-bottom: $pb-max;
}
.p-mid {
  padding-top: $pt-mid;
  padding-bottom: $pb-mid;
}
.p-min {
  padding-top: $pt-min;
  padding-bottom: $pb-min;
}

.m-max {
  margin-top: $mt-max;
  margin-bottom: $mb-max;
}
.m-mid {
  margin-top: $mt-mid;
  margin-bottom: $mb-mid;
}
.m-min {
  margin-top: $mt-min;
  margin-bottom: $mb-min;
}

//complex
.btn {
  padding: 0.5rem 1.5rem;
  background-color: lighten($primary, 10%);
  color: $white;
  border-radius: 5px;
  @include transition;

  &:hover,
  &:focus {
    color: $white;
    background-color: lighten($primary, 5%);
    box-shadow: none;
    @include transition;
  }

  &.back {
    background-color: transparent;
    color: $text-dark;
    align-items: center;
    padding: 0;

    & > :first-child {
      margin-right: 0.5rem;
      @include transition;
    }

    &:hover,
    &:focus {
      color: $text-accent;
      box-shadow: none;
      @include transition;
      & > :first-child {
        margin-right: 1rem;
        @include transition;
      }
    }
  }
}

.feature {
  width: 100%;
  border-radius: 15px;
  background-color: $white;
  padding: 3rem;
  margin: 1%;
  p {
    margin-bottom: 0;
  }
  span {
    margin-bottom: 1.5rem;
    display: block;
  }
  span,
  h3,
  h4 {
    text-align: center;
  }

  &.accent1 {
    background-color: $accent1;
  }
  &.accent2 {
    background-color: $accent2;
  }
  &.accent3 {
    background-color: $accent3;
  }
  &.accent4 {
    background-color: $accent4;
  }
  &[class*="accent"] {
    // padding: 1rem 3rem;
    padding: 1rem;
  }
  @include media-breakpoint-down(lg) {
    padding: 1.5rem;
    &[class*="accent"] {
      padding: 0.5rem;
      margin: 0.5rem;
    }
  }
}

.container {
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
}

.desktop {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.mobile {
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}

//mint page
.mint {
  min-height: calc(100vh - 57px);
  position: relative;
  z-index: 0;
  background-image: url(asstes/bg-assets/background.PNG);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

//content pages
.content {
  background-color: $primary;
  padding-top: $pt-max;
  padding-bottom: $pb-max;

  .btn {
    &.back {
      background-color: transparent;
      color: $text-light;
      display: inline-flex;
      align-items: center;

      & > :first-child {
        margin-right: 0.5rem;
        @include transition;
      }

      &:hover,
      &:focus {
        color: $text-accent;
        box-shadow: none;
        @include transition;
        & > :first-child {
          margin-right: 1rem;
          @include transition;
        }
      }
    }
  }

  ul{
    list-style: none;
    li{
      position:relative;
      &::before{
        content:'';
        background-image: url(asstes/general/dot-sm.png);
        background-size: 100% auto;
        width: 10px;
        height: 10px;
        position: absolute;
        left: -20px;
        top: 7px;
      }
    }
  }

  a:not(.btn){
    text-decoration: underline;
    color: rgba($text-accent, 1);

    &:hover{
    color: rgba($text-accent,0.7);

    }
  }
}
