//main
// $primary: #0d2136;
$primary: #023052;
$white: #efefef;
$green: green;
$red: red;


//accents
// $accent1:#E0BEC0;
// $accent2:#F1D798;
// $accent3:#B2BD9E;
$accent1:#dfbebd;
$accent2:#f3c782;
$accent3:#a1ab73;
$accent4:#4f7d8b;

//text
$text-light:#efefef;
$text-dark:#04001A;
// $text-accent:#E0BEC0; 
// $text-accent:#e47366; //orange
$text-accent:#f298c2; 



