@mixin no-link {
    color: $text-accent;
    text-decoration: none;
    @include transition();
    &:hover {
        color: darken($text-accent, 10%);
        box-shadow: none;
        @include transition();
    }
}

@mixin no-list {
    padding: 0;
    margin: 0;
    li {
        list-style: none;
    }
}

@mixin transition($speed: null) {
    @if ($speed) {
        -webkit-transition: all $speed ease;
        -moz-transition: all $speed ease;
        -ms-transition: all $speed ease;
        -o-transition: all $speed ease;
        transition: all $speed ease;
    } @else {
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
}

@mixin transformX($amount) {
    transform: translateX(-$amount);
    left: $amount;
}
@mixin transformY($amount) {
    transform: translateY(-$amount);
    top: $amount;
}

@mixin transform($amountX, $amountY) {
    transform: translate(-$amountX, -$amountY);
    top: $amountY;
    left: $amountX;
}
@mixin rotateImgWrapper($amount) {
    transform-origin: center;
    transform: rotate($amount);
    img {
        transform-origin: center;
        transform: rotate(-$amount);
    }
}
@mixin text-shadow {
    text-shadow: -2px 2px 20px rgba($text-light, 0.2);
}


//screen sizes
@mixin media-breakpoint-up($point) {
    @if $point == sm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $point == md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $point == lg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $point == xl {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else if $point == xxl {
        @media (min-width: 1400px) {
            @content;
        }
    }
}

@mixin media-breakpoint-down($point) {
    @if $point == sm {
        @media (max-width: 575px) {
            @content;
        }
    } @else if $point == md {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point == lg {
        @media (max-width: 991px) {
            @content;
        }
    } @else if $point == xl {
        @media (max-width: 1199px) {
            @content;
        }
    }@else if $point == xxl {
        @media (max-width: 1399px) {
            @content;
        }
    }
}

//spacing

$pt-max: 8rem;
$pb-max: 8rem;
$mt-max: 8rem;
$mb-max: 8rem;

$pt-mid: 4rem;
$pb-mid: 4rem;
$mt-mid: 4rem;
$mb-mid: 4rem;

$pt-min: 2rem;
$pb-min: 2rem;
$mt-min: 2rem;
$mb-min: 2rem;

// @include media-breakpoint-down(md) {
//     $pt-max: 4rem;
//     $pb-max: 4rem;
//     $mt-max: 4rem;
//     $mb-max: 4rem;

//     $pt-mid: 2rem;
//     $pb-mid: 2rem;
//     $mt-mid: 2rem;
//     $mb-mid: 2rem;

//     $pt-min: 1rem;
//     $pb-min: 1rem;
//     $mt-min: 1rem;
//     $mb-min: 1rem;
// }
