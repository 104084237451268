@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Work+Sans:ital,wght@1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&family=Montserrat:wght@900&display=swap");

@font-face {
    font-family: "New Space";
    src: url("../asstes/fonts/NewSpace.woff2") format("woff2"), url("../asstes/fonts/NewSpace.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Amateur Naked";
    src: url("../asstes/fonts/Amateur-Naked.ttf.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Milestone Script";
    src: url("../asstes/fonts/Milestone-Script.woff2") format("woff2"), url("../asstes/fonts/Milestone-Script.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "No Virus";
    src: url("../asstes/fonts/NoVirus.woff2") format("woff2"), url("../asstes/fonts/NoVirus.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//fonts
body {
    font-family: "Open Sans", sans-serif;
}

h1 {
    font-family: "Amateur Naked";
}

h2,
h3,
h4,
h5 {
    //font-family: "Work Sans", sans-serif;
    //font-family: 'Montserrat', sans-serif;
    // font-family: "Fugaz One", cursive;
    font-family: "Amateur Naked";
}

//colour
h1,
h2,
h3,
h4,
h5,
p,
a {
    color: $text-dark;
}

.dark-bg {
    h1,
    h2,
    h3,
    h4,
    h5,
    p, 
    a{
        color: $text-light;
    }
}
.light-bg {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    a {
        color: $text-dark;
    }

}

//size
p {
    font-size: 1rem;

}
h1,h2 {
    font-size: 4rem;
    margin-bottom: 3rem;
    line-height: 1;
    @include media-breakpoint-down(xxl){
        font-size: 2.7rem;
    }
    @include media-breakpoint-down(lg){

        font-size: 3.5rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    @include media-breakpoint-down(md){
        font-size: 2rem;
    }
}
h2 {
    font-size: 3.5rem;
    margin-bottom: 3rem;
    line-height: 1;

    @include media-breakpoint-down(lg){
        margin-bottom: 1.5rem;
        text-align: center;
    }
    @include media-breakpoint-down(sm){
        font-size: 2rem;
    }
}
h3 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    line-height: 1;
    @include media-breakpoint-down(xl){
        font-size: 2rem;

    }

    @include media-breakpoint-down(md){
        font-size: 1rem;
    }
}
h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1;
    @include media-breakpoint-down(md){
        font-size: 1rem;
    }
}
h5 {
    font-size: 1rem;
  
}
.emphasis{
    // font-family: "Milestone Script";
    font-family: "No Virus";
    font-size: 130%;

}
p{
    b{
        color: $text-accent;
        font-weight: normal;
    }
}